import React from "react";
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from "@mui/material/styles";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { theme } from "../lib";

const AppWrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
);

const stripePromise = loadStripe(
  document.getElementById("react-root").dataset.stripePublicKey
);

const StripeProvider = ({ children }) => {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export function createAndRenderApp(MainComponent) {
  document.addEventListener("DOMContentLoaded", () => {
    const container = document.getElementById("react-root");

    if (container) {
      const root = createRoot(container);
      root.render(
        <AppWrapper>
          <StripeProvider>
            <MainComponent />
          </StripeProvider>
        </AppWrapper>
      );
    } else {
      console.error('Could not find element with id "react-root"');
    }
  });
}

export { AppWrapper };
